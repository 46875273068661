import React, { useEffect } from "react";

// components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import API from "utils/API";
import PARAMS from "utils/PARAMS";
import Moment from 'moment';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import { Line } from 'rc-progress';

// Core
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Modal from "components/Modal/Modal";
import Result from "views/formularios/resultados";

import img1 from "assets/img/index/acuarela1.png";
import img2 from "assets/img/index/acuarela2.png";
import img3 from "assets/img/index/acuarela3.png";
import img4 from "assets/img/index/acuarela4.png";

export default function FormularioIndex() {

  const [isLoad, setIsLoad] = React.useState(false);
  const [datosIniciales, setDatosIniciales] = React.useState(localStorage.getItem("datos_iniciales"));
  const [form1Realizaction, setForm1Realizaction] = React.useState(null);
  const [form1Total, setForm1Total] = React.useState(null);
  const [form1Activo, setForm1Activo] = React.useState(null);
  const [form2Realizaction, setForm2Realizaction] = React.useState(null);
  const [form2Total, setForm2Total] = React.useState(null);
  const [form2Activo, setForm2Activo] = React.useState(null);
  const [form3Realizaction, setForm3Realizaction] = React.useState(null);
  const [form3Total, setForm3Total] = React.useState(null);
  const [form3Activo, setForm3Activo] = React.useState(null);
  const [form4Realizaction, setForm4Realizaction] = React.useState(null);
  const [form4Total, setForm4Total] = React.useState(null);
  const [form4Activo, setForm4Activo] = React.useState(null);
  const [form5Realizaction, setForm5Realizaction] = React.useState(null);
  const [form5Total, setForm5Total] = React.useState(null);
  const [form5Activo, setForm5Activo] = React.useState(null);

  const [dataPdf, setDataPdf] = React.useState(null);

  const [isCompleted, setIsCompleted] = React.useState(false);
  const [formPendientes, setFormPendientes] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  }

  const styles = {
    card: {
      maxWidth: 345,
    },
    media: {
      height: 180,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgb(192, 94, 109)'
    },
    cardHeader:{
      height: '170px',
      display: 'flex',
      justifyContent:"center",
      flexDirection:"column"
    },
    cardBtn:{
      display: 'flex',
      justifyContent:"center",
      flexDirection:"column"
    }
  };
  const useStyles = makeStyles(styles);
  Moment.locale('es');

  useEffect(() => {
    getUserTestRealizaction();
  }, []);

  function isFormComplete(data){
    if (data.completado == data.total && data.activo == 1) {
      return true;
    }
    if (data.completado != data.total && data.activo == 0) {
      return true;
    }
    return false;
  }

  async function getUserTestRealizaction() {
    setIsLoad(false);
    await API.post("respuestas/getUserTestRealizaction")
    .then(res => {
      if (Object.keys(res.data).length > 0) {
        setForm1Realizaction(res.data[4].completado);
        setForm1Total(res.data[4].total);
        setForm1Activo(res.data[4].activo);

        setForm2Realizaction(res.data[3].completado);
        setForm2Total(res.data[3].total);
        setForm2Activo(res.data[3].activo);

        setForm3Realizaction(res.data[1].completado);
        setForm3Total(res.data[1].total);
        setForm3Activo(res.data[1].activo);

        setForm4Realizaction(res.data[2].completado);
        setForm4Total(res.data[2].total);
        setForm4Activo(res.data[2].activo);

        setForm5Realizaction(res.data[5].completado);
        setForm5Total(res.data[5].total);
        setForm5Activo(res.data[5].activo);
        setIsLoad(true);
        // if (isFormComplete(res.data[5]) && isFormComplete(res.data[4]) && isFormComplete(res.data[3]) && isFormComplete(res.data[2]) && isFormComplete(res.data[1])) {
        //   setIsCompleted(true);
        // }
        if (isFormComplete(res.data[5]) && isFormComplete(res.data[4]) && isFormComplete(res.data[2]) && isFormComplete(res.data[1])) {
          setIsCompleted(true);
        }
        var pendientes = [];
        if (!isFormComplete(res.data[1]) && res.data[1].activo == 1) {
          pendientes.push('Inteligencias Múltiples');
        }
        if (!isFormComplete(res.data[2]) && res.data[2].activo == 1) {
          pendientes.push('Intereses Profesionales');
        }
        // if (!isFormComplete(res.data[3]) && res.data[3].activo == 1) {
        //   pendientes.push('Bienestar Psicológico');
        // }
        if (!isFormComplete(res.data[4]) && res.data[4].activo == 1) {
          pendientes.push('Satisfacción con la vida');
        }
        if (!isFormComplete(res.data[5]) && res.data[5].activo == 1) {
          pendientes.push('Bienestar Psicológico');
        }
        setFormPendientes(pendientes);
      }
    })
    .catch(err => {
      console.log(err);
    });
  }

  async function generatePdf() {
    let data = JSON.stringify(dataPdf);
    let axiosApi = API;
    axiosApi.defaults.responseType = 'blob';
    await axiosApi.post("respuestas/generatePdf",{
      data: data
    })
    .then(res => {
      if (res) {
        //Ver en linea
        // const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
        // window.open(url);
        // Descarga
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch(err => {
      console.log(err);
    });
  }

  function modalNoDisponible(){
    return(
      <GridItem xs={12} sm={12} md={12}>
        Debes rellenar los datos personales
      </GridItem>
    )
  }

  function handleForm(form){
    if (datosIniciales == '0') {
      setOpen(true);
    }else{
      window.location.href = PARAMS.baseUrl + 'participante/formulario/' + form;
    }
  }

  const classes = useStyles();

  return (
    <div style={{marginTop: '-50px'}}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} align="center">
          <Typography variant="body2" color="textSecondary" component="p" style={{marginBottom: '15px', fontSize: '19px'}}>
            Aunque, como ya sabes, todo el proceso de recopilación de información es ANÓNIMO, necesitamos conocer tu perfil en el bloque de Datos Personales. Después, podrás completar los test que encontrarás más abajo. Una vez hayas finalizado las pruebas, podrás acceder a tu informe personal. Pero, recuerda, para obtener éste deberás responder a todas las cuestiones.
          </Typography>
          <GridItem xs={12} sm={12} md={12} align="center">
            <Result completado={isCompleted} formPendientes={formPendientes} dataPdf={(data) => setDataPdf(data)} style={{position: "absolute", right: '0px'}}/>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} align="center">
            {isCompleted && dataPdf ? <>
              <Button onClick={() => generatePdf()} variant="contained" size="medium" color="primary" style={{marginBottom: '15px', backgroundColor: PARAMS.firstColor}}>
                Obtener informe personal
              </Button>
            </> : <>
              {isCompleted && <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} height={80} width={80} />}
            </>}
          </GridItem>
          <Button onClick={() => window.location.href = PARAMS.baseUrl + 'participante/formInit'} variant="contained" size="medium" color="primary" style={{marginBottom: '15px', backgroundColor: PARAMS.firstColor}}>
            Datos Personales
          </Button>
        </GridItem>
        {/* Inteligencia Multiples */}
        {form3Activo == 1 &&
          <GridItem xs={12} sm={6} md={3} style={{padding: '0 5px'}}>
            <Card>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={null}
                >
                  <Typography gutterBottom variant="h4" component="h2" style={{color: '#fff', textShadow: '2px 2px 2px rgba(0,0,0,0.6)', textAlign: 'center'}}>
                    Inteligencias Múltiples
                  </Typography>
                </CardMedia>
                <CardContent style={{height: '200px'}}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    El Cuestionario de Autoevaluación de las Inteligencias Múltiples (CAIM) es un instrumento destinado al autoconocimiento de los potenciales y talentos personales clasificados en siete tipos de inteligencia.
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" style={{position: 'absolute', bottom: '0px'}}>
                    Este test ha sido desarrollado por el Dr. Roberto Kertész, en 2006, Universidad de Flores.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions className={classes.cardBtn}>
                {(isLoad ?
                  <>
                  <Typography gutterBottom variant="h5" component="h2" style={{width: '100%', textAlign: 'center'}}>
                    <Line percent={(form3Realizaction*100)/form3Total} strokeWidth="5" strokeColor={PARAMS.firstColor} />
                      {form3Realizaction} / {form3Total}
                    </Typography>
                    <Button onClick={() => handleForm('inteligencias_multiples')} style={{color: '#fff', backgroundColor: PARAMS.firstColor}}>
                      {form3Realizaction !== form3Total ? <>{form3Realizaction === 0 ? <> Comenzar </> : <> Continuar</> }</> : <>Revisar</> }
                    </Button>
                  </>
                :
                  <>
                    <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={3000} height={80} width={80} />
                  </>
                )}
              </CardActions>
            </Card>
          </GridItem>
        }
        {/* Intereses Profesionales */}
        {form4Activo == 1 &&
          <GridItem xs={12} sm={6} md={3} style={{padding: '0 5px'}}>
            <Card>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={null}
                >
                  <Typography gutterBottom variant="h4" component="h2" style={{color: '#fff', textShadow: '2px 2px 2px rgba(0,0,0,0.6)', textAlign: 'center'}}>
                    Intereses Profesionales
                  </Typography>
                </CardMedia>
                <CardContent style={{height: '200px'}}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Este test permite descubrir los intereses o preferencias por distintos campos de conocimiento y sus correspondientes áreas profesionales.
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" style={{position: 'absolute', bottom: '0px'}}>
                    Este test ha sido desarrollado por la Generalitat Valenciana
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions className={classes.cardBtn}>
                {(isLoad ?
                  <>
                  <Typography gutterBottom variant="h5" component="h2" style={{width: '100%', textAlign: 'center'}}>
                    <Line percent={(form4Realizaction*100)/form4Total} strokeWidth="5" strokeColor={PARAMS.firstColor} />
                      {form4Realizaction} / {form4Total}
                    </Typography>
                    <Button onClick={() => handleForm('intereses_profesionales')} style={{color: '#fff', backgroundColor: PARAMS.firstColor}}>
                      {form4Realizaction !== form4Total ? <>{form4Realizaction === 0 ? <> Comenzar </> : <> Continuar</> }</> : <>Revisar</> }
                    </Button>
                  </>
                :
                  <>
                    <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={3000} height={80} width={80} />
                  </>
                )}
              </CardActions>
            </Card>
          </GridItem>
        }
        {/* Bienestar Psicológico */}
        {form2Activo == 1 &&
          <GridItem xs={12} sm={6} md={3} style={{padding: '0 5px'}}>
            <Card>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={null}
                >
                  <Typography gutterBottom variant="h4" component="h2" style={{color: '#fff', textShadow: '2px 2px 2px rgba(0,0,0,0.6)', textAlign: 'center'}}>
                    Bienestar Psicológico
                  </Typography>
                </CardMedia>
                <CardContent style={{height: '200px'}}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    La finalidad de este test es aproximar el bienestar psicológico, entendido como la consecución de aquellos valores que nos hacen crecer como personas.
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" style={{position: 'absolute', bottom: '0px'}}>
                    Escala de Bienestar Psicológico de Ryff, desarrollada por Carol Ryff en el año 1995 y adaptada a la versión española por Díaz et al. en 2006.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions className={classes.cardBtn}>
                {(isLoad ?
                  <>
                  <Typography gutterBottom variant="h5" component="h2" style={{width: '100%', textAlign: 'center'}}>
                    <Line percent={(form2Realizaction*100)/form2Total} strokeWidth="5" strokeColor={PARAMS.firstColor} />
                      {form2Realizaction} / {form2Total}
                    </Typography>
                    <Button onClick={() => handleForm('bp_ryff')} style={{color: '#fff', backgroundColor: PARAMS.firstColor}}>
                      {form2Realizaction !== form2Total ? <>{form2Realizaction === 0 ? <> Comenzar </> : <> Continuar</> }</> : <>Revisar</> }
                    </Button>
                  </>
                :
                  <>
                    <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={3000} height={80} width={80} />
                  </>
                )}
              </CardActions>
            </Card>
          </GridItem>
        }
        {/* Bienestar Psicológico Desarrollo Propio */}
        {form5Activo == 1 &&
          <GridItem xs={12} sm={6} md={3} style={{padding: '0 5px'}}>
            <Card>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={null}
                >
                  <Typography gutterBottom variant="h4" component="h2" style={{color: '#fff', textShadow: '2px 2px 2px rgba(0,0,0,0.6)', textAlign: 'center'}}>
                    Bienestar Psicológico
                  </Typography>
                </CardMedia>
                <CardContent style={{height: '200px'}}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    La finalidad de este test es aproximar el bienestar psicológico, entendido como la consecución de aquellos valores que nos hacen crecer como personas.
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" style={{position: 'absolute', bottom: '0px'}}>
                    Desarrollo Propio
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions className={classes.cardBtn}>
                {(isLoad ?
                  <>
                  <Typography gutterBottom variant="h5" component="h2" style={{width: '100%', textAlign: 'center'}}>
                    <Line percent={(form5Realizaction*100)/form5Total} strokeWidth="5" strokeColor={PARAMS.firstColor} />
                      {form5Realizaction} / {form5Total}
                    </Typography>
                    <Button onClick={() => handleForm('bp_custom')} style={{color: '#fff', backgroundColor: PARAMS.firstColor}}>
                      {form5Realizaction !== form5Total ? <>{form5Realizaction === 0 ? <> Comenzar </> : <> Continuar</> }</> : <>Revisar</> }
                    </Button>
                  </>
                :
                  <>
                    <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={3000} height={80} width={80} />
                  </>
                )}
              </CardActions>
            </Card>
          </GridItem>
        }
        {/* Satisfacción con la vida */}
        {form1Activo == 1 &&
          <GridItem xs={12} sm={6} md={3} style={{padding: '0 5px'}}>
            <Card>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={null}
                >
                  <Typography gutterBottom variant="h4" component="h2" style={{color: '#fff', textShadow: '2px 2px 2px rgba(0,0,0,0.6)', textAlign: 'center'}}>
                    Satisfacción con la vida
                  </Typography>
                </CardMedia>
                <CardContent style={{height: '200px'}}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    El objetivo de este test es medir la satisfacción con la vida y hace referencia a la experiencia subjetiva de la felicidad.
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" style={{position: 'absolute', bottom: '0px'}}>
                    Escala de Satisfacción Vital (SWLS) desarrollada por Ed Diener, Robert A. Emmons, Randy J. Larsen y Sharon Griffin en 1985 (Journal of Personality Assessment.)
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions className={classes.cardBtn}>
                {(isLoad ?
                  <>
                    <Typography gutterBottom variant="h5" component="h2" style={{width: '100%', textAlign: 'center'}}>
                      <Line percent={(form1Realizaction*100)/form1Total} strokeWidth="5" strokeColor={PARAMS.firstColor} />
                      {form1Realizaction} / {form1Total}
                    </Typography>
                    <Button onClick={() => handleForm('swls')} style={{color: '#fff', backgroundColor: PARAMS.firstColor}}>
                      {form1Realizaction !== form1Total ? <>{form1Realizaction === 0 ? <> Comenzar </> : <> Continuar</> }</> : <>Revisar</> }
                    </Button>
                  </>
                :
                  <>
                    <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={3000} height={80} width={80} />
                  </>
                )}
              </CardActions>
            </Card>
          </GridItem>
        }
        <GridItem xs={12} sm={12} md={12} align="center">
          {(isCompleted && dataPdf) ? <>
            <Button onClick={() => generatePdf()} variant="contained" size="medium" color="primary" style={{marginBottom: '15px', backgroundColor: PARAMS.firstColor}}>
              Obtener informe personal
            </Button>
          </> : <>
            {isCompleted && <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} height={80} width={80} />}
          </>}
        </GridItem>
        <Modal
          open={open}
          onConfirm={() => handleClose()}
          content={modalNoDisponible()}
          onlyConfirm={true}
          confirmText="Ok"
          confirmIcon={<></>} />
      </GridContainer>
    </div>
  );
}
